<template>
    <div>
        <VueSlickCarousel v-bind="settings" v-if="getCompanyInfo.website_content.slider_img1">
            <div v-if="getCompanyInfo.website_content.slider_img1">
                <img v-lazy="slideImage1" alt="bus image 1" width="auto" height="auto">
            </div>
            <div v-if="getCompanyInfo.website_content.slider_img2">
                <img v-lazy="slideImage2" alt="bus image 2" width="auto" height="auto">
            </div>
            <div v-if="getCompanyInfo.website_content.slider_img3">
                <img v-lazy="slideImage3" alt="bus image 3" width="auto" height="auto">
            </div>
            <div v-if="getCompanyInfo.website_content.slider_img4">
                <img v-lazy="slideImage4" alt="bus image 4" width="auto" height="auto">
            </div>
            <div v-if="getCompanyInfo.website_content.slider_img5">
                <img v-lazy="slideImage5" alt="bus image 5" width="auto" height="auto">
            </div>
            <div v-if="getCompanyInfo.website_content.slider_img6">
                <img v-lazy="slideImage6" alt="bus image 6" width="auto" height="auto">
            </div>
        </VueSlickCarousel>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import {mapGetters} from 'vuex';

export default {
    name: 'PlaceSlider',
    components: { VueSlickCarousel },
    data() {
        return {
            settings: {
                "dots": false,
                "arrows": false,
                "centerMode": true,
                "variableWidth": true,
                "adaptiveHeight": true,
                "infinite": true,
                "autoplay": true,
                "speed": 500,
                "pauseOnHover": false,
                "slidesToShow": 3,
                "slidesToScroll": 1,
                "responsive": [
                    {
                        "breakpoint": 1200,
                        "settings": {
                            "slidesToShow": 3,
                            "slidesToScroll": 1,
                        }
                    },
                    {
                        "breakpoint": 1000,
                        "settings": {
                            "slidesToShow": 2,
                            "slidesToScroll": 1
                        }
                    },
                    {
                        "breakpoint": 480,
                        "settings": {
                            "slidesToShow": 1,
                            "slidesToScroll": 1
                        }
                    }
                ]
            },
        }
    },
    computed:{
        ...mapGetters(['getCompanyInfo']),
        slideImage1: function () {
            return this.getCompanyInfo.website_content.slider_img1;
        },
        slideImage2: function () {
            return this.getCompanyInfo.website_content.slider_img2;
        },
        slideImage3: function () {
            return this.getCompanyInfo.website_content.slider_img3;
        },
        slideImage4: function () {
            return this.getCompanyInfo.website_content.slider_img4;
        },
        slideImage5: function () {
            return this.getCompanyInfo.website_content.slider_img5;
        },
        slideImage6: function () {
            return this.getCompanyInfo.website_content.slider_img6;
        }
    }
}
</script>
